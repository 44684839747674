<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-08-02 16:57:46
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-03 10:20:44
-->
<template>
  <div class="business_card">
    <a-card :bordered="false">
      <div v-if="handle === 'detail'">
        <a-form-model layout="horizontal" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-form-model-item label="是否必填合同号">{{ detail.flagContractNo ? '是' : '否' }}</a-form-model-item>
          <a-form-model-item label="登陆设置">{{ detail.type == 1 ? '首登' : detail.type == 2 ? '二登' : '不限制' }}</a-form-model-item>
          <a-form-model-item label="是否设置失效时长">{{ detail.flagExpire ? '是' : '否' }}</a-form-model-item>
          <a-form-model-item label="失效时长（天）" v-if="detail.flagExpire">{{ detail.expireDays != null ? detail.expireDays : '未设置' }}</a-form-model-item>
          <a-form-model-item label="是否设置审核延时">{{ detail.flagDelay ? '是' : '否' }}</a-form-model-item>
          <a-form-model-item label="延时时间（分钟）" v-if="detail.flagDelay">{{ detail.delayTime != null ? detail.delayTime : '未设置' }}</a-form-model-item>

          <div class="footer-btns">
            <a-button v-if="isEdit" type="default" @click="onEdit">修改</a-button>
          </div>
        </a-form-model>
      </div>
      <div v-else>
        <a-form-model
          ref="form"
          :model="detail"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-model-item label="是否必填合同号" prop="flagContractNo">
            <a-radio-group v-model="detail.flagContractNo">
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="登录设置" prop="type">
            <a-radio-group v-model="detail.type">
              <a-radio :value="1"> 首登 </a-radio>
              <a-radio :value="2"> 二登 </a-radio>
              <a-radio :value="3"> 不限制 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="是否设置失效时长" prop="flagExpire">
            <a-radio-group v-model="detail.flagExpire" @change="onChange">
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="checkRadio === true" label="失效时长(天)" :prop="checkRadio === true ? 'expireDays' : ''">
            <a-input-number v-model="detail.expireDays" :min="checkRadio === true ? 1 : ''"></a-input-number>
          </a-form-model-item>
          <a-form-model-item label="是否设置审核延时" prop="flagDelay">
            <a-radio-group v-model="detail.flagDelay" @change="onChangeDelay">
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="checkDelayRadio === true" label="延时时间(分钟)" :prop="checkDelayRadio === true ? 'delayTime' : ''">
            <a-input-number v-model="detail.delayTime" :min="checkDelayRadio === true ? 1 : ''"></a-input-number>
          </a-form-model-item>
          <div class="footer-btns">
            <a-button type="default" @click="onSubmit">保存</a-button>
            <a-button type="default" @click="onHome">返回</a-button>
          </div>
        </a-form-model>
      </div>
    </a-card>
  </div>
</template>

<script>
import {checkPermission} from '@/utils/permissions'

export default {
  name: '',
  data() {
    return {
      handle: 'detail',
      detail: {},
      formRule: {
        type: [{ required: true, message: '请选择登录设置', trigger: 'change' }],
        flagContractNo: [{ required: true, message: '请选择是否必填合同号', trigger: 'change' }],
        flagExpire: [{ required: true, message: '请选择是否设置失效时长', trigger: 'change' }],
        expireDays: [{ required: true, message: '请输入失效时长', trigger: 'change' }],
        flagDelay: [{ required: true, message: '请选择是否设置审核延时', trigger: 'change' }],
        delayTime: [{ required: true, message: '请输入延时时间', trigger: 'change' }],
      },
      isEdit: checkPermission('pre_order_setting:edit'),
      checkRadio: false
    }
  },

  computed: {},
  created() {
    this.getDetail()
  },
  methods: {
    // 获取名片详情
    getDetail() {
      let firmId = this.$store.getters.userInfo.firmId
      this.axios.get(`/api/firm/mhiac/mhiacFirm/getByFirmId/${firmId}`).then((res) => {
        if (res.code == 200) {
          this.detail = res.body
        }
      })
    },

    // 打开修改框
    onEdit() {
      if(this.detail.flagExpire){
        this.checkRadio = true;
      }
      if(this.detail.flagDelay){
        this.checkDelayRadio = true;
      }
      this.handle = 'edit'
    },

    // 返回
    onHome() {
      this.handle = 'detail'
    },

    onChange(val) {
      this.checkRadio = val.target.value
      this.detail.expireDays = ''
      if(this.checkRadio) {
        this.detail.expireDays = 30
      }
    },

    onChangeDelay(val) {
      this.checkDelayRadio = val.target.value
      this.detail.delayTime = ''
      if(this.checkDelayRadio) {
        this.detail.delayTime = 240
      }
    },


    // 提交数据
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.detail.firmId = this.$store.getters.userInfo.firmId

        this.axios.post(`/api/firm/mhiac/mhiacFirm/updateContract`, this.detail).then((res) => {
          if (res.code == 200) {
            this.handle = 'detail'
            this.$message.success(res.message)
            this.getDetail()
          }
        }).catch(err => {
          this.getDetail()
        })
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.footer-btns {
  text-align: right;
  margin-top: 20px;
}
</style>
